
import { Vue, Component, ModelSync, Prop, Ref } from "vue-property-decorator";
import { mapGetters } from "vuex";

import * as types from "@/store/types";
import { UserFormInterface, FormActions } from "@/interfaces";

@Component({
    computed: {
        ...mapGetters({
            userRoles: types.ALL_ROLES
        })
    }
})
export default class UserForm extends Vue {
    @ModelSync("userForm", "change", { type: Object })
    formValues!: UserFormInterface;

    @Prop({ type: Boolean, required: false, default: false }) edit!: boolean;

    @Ref("form") readonly formActions!: FormActions;

    userRoles!: string[];

    showPassword: boolean | null = false;

    formRules = {
        username: [val => !!val || "Username is required"],
        email: [
            val => !!val || "Email is required",
            val => /.+@.+\..+/.test(val) || "E-mail must be valid"
        ],
        password: [
            val => !!val || "Password is required",
            val =>
                (val && val.length >= 8) ||
                "Password must be at leas 8 characters"
        ],
        role: [val => val.length > 0 || "Role is required"]
    };

    /**
     * Methods
     */
    reset() {
        this.formActions.reset();
        this.formActions.resetValidation();
    }
}
